/** @format */

import queryString from 'query-string';
import {isObjectLike} from 'lodash';
import type {AnyObject, AppAxiosError, CookieName} from 'interfaces/shared';
import {notification} from 'antd';

/**
 * @param time miliseconds
 * @default 3000
 */
export const sleep = (time = 3000) => new Promise(r => setTimeout(r, time));

export const isValidObjectId = (id: string) => {
  console.log('🚀 ~ isValidObjectId ~ id:', id);
  const objectIdRegex = new RegExp('^[0-9a-fA-F]{24}$');
  return objectIdRegex.test(id);
};

export function getCookie(name: CookieName) {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return JSON.parse(match[2]);
}
export function setCookie(name: CookieName, payload: object) {
  document.cookie = `${name}=${JSON.stringify(payload)};path=/;Max-Age=${60 * 60 * 24 * 7}`;
}
export function removeCookie(name: CookieName) {
  document.cookie = `${name}=`;
}

export const getInitialsName = (name: string): string => {
  if (!name) return '';
  const words = name.split(' ');
  let result = '';
  for (const word of words) {
    result += word.charAt(0).toUpperCase();
  }
  if (!result) {
    console.warn('Could not get abbr from name');
    result = name;
  }
  return result;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const cValue = parseInt(c.join(''), 16);
    const r = (cValue >> 16) & 255;
    const g = (cValue >> 8) & 255;
    const b = cValue & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  throw new Error('Mã màu hex không hợp lệ');
};

export const isFile = (value: any): boolean => value instanceof File || value instanceof Blob;

export const transformPostData = (object: AnyObject = {}) => {
  const newObject: AnyObject = {};
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      newObject[key] = JSON.stringify(value);
    } else {
      newObject[key] = value;
    }
  }
  return queryString.stringify(newObject);
};

export const transformPostFormData = (object: AnyObject | FormData = {}): FormData => {
  if (object instanceof FormData) {
    return object;
  }
  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      if (Array.isArray(value) && value.every(isFile)) {
        value.forEach((v: any) => {
          formData.append(key, v);
        });
      } else if (isFile(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    } else if (value != null) {
      formData.append(key, value);
    }
  }
  return formData;
};

export const checkStringType = (input: string) => {
  const num = Number(input);
  if (!Number.isNaN(num) && input.trim() !== '') {
    return false;
  } else {
    return true;
  }
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const removeUndefinedObject = (obj: any) => {
  Object.keys(obj).forEach(k => {
    if (obj[k] === '' || obj[k] === null || obj[k] === undefined) {
      delete obj[k];
    }
    if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      removeUndefinedObject(obj[k]);
    }
  });
  return obj;
};

export const handleNotifyErrorApi = (error: Error) => {
  let err = error as AppAxiosError;
  if (err.response) {
    notification.error({
      message: err.code,
      showProgress: true,
      description: err.response?.data.message,
      duration: 5,
    });
  }
};

export const mapValueLabel = <T, KV extends keyof T, KL extends keyof T>(list: T[] | undefined, keyValue: KV, keyLabel: KL) => {
  return list?.map(x => ({
    ...x,
    value: x[keyValue],
    label: x[keyLabel],
  }));
};
