import {Spin} from 'antd';
import {Suspense} from 'react';
import {Flex} from 'components/base';
import {Outlet} from 'react-router-dom';
import {BackgroundLogin} from 'assets/images';

export default function PublicLayout() {
  return (
    <Flex vertical minHeight="100dvh" height="100dvh" position="relative">
      <BackgroundLogin />
      <Flex position="absolute" width="100%" height="100%" align="center" justify="center" top={0} left={0}>
        <Suspense fallback={<Spin spinning fullscreen />}>
          <Outlet />
        </Suspense>
      </Flex>
    </Flex>
  );
}
