/** @format */

import {lazy} from 'react';
import {getCookie} from 'utils/helpers';
import {createBrowserRouter, Navigate, type RouteObject} from 'react-router-dom';
import PrivateLayout from 'layouts/auth';
import PublicLayout from 'layouts/public';

// removeCookie('kinis');
const accessToken = getCookie('kinis');
console.log('🚀 ~ accessToken:', accessToken);

const createRoute = (path: string, component: string): RouteObject => ({
  path,
  Component: lazy(() => import(`pages/${component}`)),
});

// Route definitions for both Private and Public layouts
const commonRoutes: RouteObject[] = [
  {path: '/', element: <Navigate to={accessToken ? 'dashboard' : 'sign-in'} />},
  {path: '*', element: <Navigate to={accessToken ? 'dashboard' : 'sign-in'} />},
];

const publicRoutes: RouteObject[] = [
  ...commonRoutes,
  createRoute('sign-in/*', 'public/login'),
  createRoute('sign-up/*', 'public/register'),
  createRoute('forget-password/*', 'forget-password'),
];

const privateRoutes: RouteObject[] = [
  ...commonRoutes,
  createRoute('dashboard/*', 'dashboard'),
  createRoute('trainer/*', 'trainer'),
  createRoute('chat/*', 'detector'),
  createRoute('client/*', 'client'),
  createRoute('manager/*', 'manager'),
  createRoute('staff/*', 'system/staff'),
  createRoute('states/*', 'system/states'),
  createRoute('organization/*', 'system/organization'),
];

export const router = createBrowserRouter([
  {
    path: '/',
    element: accessToken ? <PrivateLayout /> : <PublicLayout />,
    children: accessToken ? privateRoutes : publicRoutes,
  },
]);
