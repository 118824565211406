/** @format */

import React from 'react';
import LayoutBreadcrumbs from './Breadcrumb';
import {Layout, Flex, Badge, Avatar, type MenuProps} from 'antd';
import {MenuUnfoldOutlined, MenuFoldOutlined, BellFilled} from '@ant-design/icons';
import {COLORS} from 'themes/color';
import {Button} from 'components/base';

interface HeaderLayoutProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const items: MenuProps['items'] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: '3rd menu item',
    key: '3',
  },
];

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({collapsed, setCollapsed}) => {
  return (
    <Layout.Header style={{background: COLORS.white}}>
      <Flex align="center" style={{paddingRight: 30}}>
        <Flex flex={1} align="center">
          <Button
            type="text"
            width={64}
            height={64}
            onClick={() => setCollapsed(!collapsed)}
            icon={
              collapsed ? <MenuUnfoldOutlined style={{fontSize: 18}} /> : <MenuFoldOutlined style={{fontSize: 18}} />
            }
          />
          <LayoutBreadcrumbs />
        </Flex>
        <Flex gap={12}>
          <Badge count={5} size="small">
            <Button
              type="text"
              width={36}
              height={36}
              borderRadius={18}
              backgroundColor={COLORS.secondary100}
              icon={<BellFilled width={30} height={30} />}
            />
          </Badge>
          <Avatar children="Q" />
          <Button backgroundColor={COLORS.baseWhite} children="Quan" />
        </Flex>
      </Flex>
    </Layout.Header>
  );
};
