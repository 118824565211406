import {QueryClient, keepPreviousData} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      gcTime: Infinity,
      staleTime: Infinity,
      placeholderData: keepPreviousData,
    },
  },
});
