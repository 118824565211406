/** @format */

export const COLORS = {
  title: '#566a7f',
  text: '#697a8d',
  white: '#ffffff',
  baseWhite: '#FAFAFA',
  success: '',
  success100: '',
  success200: '',
  success300: '#0B7B69',
  warning: '',
  warning100: '',
  warning200: '',
  warning300: '',
  error: '#CC0000',
  error100: '',
  error300: '',
  error200: '',
  primary: '#F3742D',
  primary100: '#FDE8DC',
  primary200: '',
  primary300: '',
  primary400: '',
  primary500: '',
  primary600: '',
  primary700: '',
  primary800: '',
  primary900: '',
  primary1000: '',
  secondary: '',
  secondary100: '#EAEAEA',
  secondary200: '#EAEAEA',
  secondary300: '#EAEAEA',
  secondary400: '#EAEAEA',
  secondary500: '#EAEAEA',
  secondary600: '#EAEAEA',
  secondary700: '#EAEAEA',
  secondary800: '#EAEAEA',
  secondary900: '#EAEAEA',
  secondary1000: '#EAEAEA',

  gray50: '',
  gray100: '#F1F5F9',
  gray200: '',
  gray300: '#CBD5E1',
  gray400: '#94A3B8',
  gray500: '',
  gray600: '',
  gray700: '#334155',
  gray800: '',
  gray900: '',
  transparentWhite800: 'rgba(255, 255, 255, 0.80)',
  background: '#F5F6FA',
  placeholder: '#B3B3B3',
  textPrimaryLight: '#111827',
  boxShadow: '0 6px 16px -8px rgba(0, 0, 0, 0.08)',
  shadowHeader: '0px 10px 40px -25px rgba(0,0,0,0.49)',
  btnGradient: 'linear-gradient(135deg, #31c48d, #0e9f6e, #008040)',
};
