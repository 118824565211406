import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Breadcrumb, Space} from 'antd';
import {MdOutlineChevronRight} from 'react-icons/md';
import {HomeOutlined} from '@ant-design/icons';
import { IconMenu } from './Sider';

type IconMenuInfo = typeof IconMenu;

interface BreadcrumbItem {
  title: string | React.ReactNode;
  href: string;
}

export default function LayoutBreadcrumbs() {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const breadcrumbsHome = useMemo(() => {
    return {
      href: '/',
      title: (
        <Fragment>
          <HomeOutlined />
          <span>Home</span>
        </Fragment>
      ),
    };
  }, []);

  useEffect(() => {
    const pathArray = location.pathname.split('/').filter(path => path);
    const newBreadcrumbs = pathArray.map((path, index) => ({
      href: `/${pathArray.slice(0, index + 1).join('/')}`,
      title: (
        <Space size="small">
          {IconMenu[path as keyof IconMenuInfo]}
          <span>{path.charAt(0).toUpperCase() + path.slice(1)}</span>
        </Space>
      ),
    }));
    setBreadcrumbs([breadcrumbsHome, ...newBreadcrumbs]);
  }, [location]);

  return <Breadcrumb separator={<MdOutlineChevronRight size={20} />} items={breadcrumbs} />;
}
