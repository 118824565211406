import {Suspense, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Layout, Spin} from 'antd';
import {SiderLayout} from './components/Sider';
import {HeaderLayout} from './components/Header';

export default function AuthLayout() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{minHeight: '100vh'}}>
      <SiderLayout collapsed={collapsed} />
      <Layout>
        <HeaderLayout collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout.Content style={{padding: 12}}>
          <Suspense fallback={<Spin spinning fullscreen />}>
            <Outlet />
          </Suspense>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
