/** @format */

import React, {type CSSProperties} from 'react';
import type {MenuProps} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {GoDotFill} from 'react-icons/go';
import {BsFillEvFrontFill} from 'react-icons/bs';
import {Layout, Menu} from 'antd';
import {COLORS} from 'themes/color';
import {RiProfileLine} from 'react-icons/ri';
import {FaLocationCrosshairs, FaMapLocationDot, FaUserGroup} from 'react-icons/fa6';
import {RiListSettingsFill} from 'react-icons/ri';
import {GiftOutlined, PieChartOutlined, FileDoneOutlined, TransactionOutlined} from '@ant-design/icons';
import {TbCalendarTime} from 'react-icons/tb';
import {LiaMoneyCheckAltSolid} from 'react-icons/lia';
import {HiChatBubbleLeftRight} from 'react-icons/hi2';
import {IoFolderOpen} from 'react-icons/io5';
import {logo} from 'assets/icons';

type SiderLayoutProps = {
  collapsed: boolean;
};

export const SiderLayout: React.FC<SiderLayoutProps> = ({collapsed}) => {
  const location = useLocation();

  return (
    <Layout.Sider width={256} collapsed={collapsed} style={SiderStyles}>
      <div className="logo" style={{margin: '16px', textAlign: collapsed ? 'center' : 'left', color: COLORS.white}}>
        <img src={logo} alt="logo" width={collapsed ? '100%' : '25%'} />
      </div>
      <Menu
        mode="inline"
        items={MENU_LIST}
        selectedKeys={[location.pathname.slice(1)]}
        defaultOpenKeys={[location.pathname.slice(1)]}
        defaultSelectedKeys={[location.pathname.slice(1)]}
      />
    </Layout.Sider>
  );
};

const SiderStyles: CSSProperties = {
  overflowX: 'auto',
};

type MenuItem = Required<MenuProps>['items'][number];

export const IconMenu = {
  dashboard: <PieChartOutlined />,
  user: <FaUserGroup />,
  chat: <HiChatBubbleLeftRight />,
  promotion: <GiftOutlined />,
  transaction: <TransactionOutlined />,
  report: <FileDoneOutlined />,
  profile: <RiProfileLine />,
  station: <FaMapLocationDot />,
  settings: <RiListSettingsFill />,
  realTime: <BsFillEvFrontFill />,
  location: <FaLocationCrosshairs />,
  calendar: <TbCalendarTime style={{fontSize: 18}} />,
  money: <LiaMoneyCheckAltSolid />,
  dot: <GoDotFill />,
  resources: <IoFolderOpen />,
};

export const MENU_LIST: MenuItem[] = [
  {
    key: 'dashboard',
    icon: IconMenu['dashboard'],
    label: <Link to="dashboard">Dashboard</Link>,
  },
  {
    key: 'chat',
    icon: IconMenu['chat'],
    label: <Link to="chat">Chat portal</Link>,
  },
  {
    key: 'user',
    type: 'group',
    label: 'User management',
    children: [
      {
        key: 'client',
        icon: IconMenu['user'],
        label: <Link to="client">Client</Link>,
      },
      {
        key: 'trainer',
        icon: IconMenu['user'],
        label: <Link to="trainer">Trainer</Link>,
      },
      {
        key: 'manager',
        icon: IconMenu['user'],
        label: <Link to="manager">Manager</Link>,
      },
    ],
  },
  {
    key: 'system',
    type: 'group',
    label: 'System management',
    children: [
      {
        key: 'staff',
        icon: IconMenu['resources'],
        label: <Link to="staff">Staff</Link>,
      },
      {
        key: 'states',
        icon: IconMenu['resources'],
        label: <Link to="states">States</Link>,
      },
      {
        key: 'organization',
        icon: IconMenu['resources'],
        label: <Link to="organization">Organization</Link>,
      },
    ],
  },
];
