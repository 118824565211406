import type {ThemeConfig} from 'antd';
import {COLORS} from './color';

export const THEME_ANTD: ThemeConfig = {
  token: {
    colorPrimary: COLORS.primary,
    colorSuccess: COLORS.primary,
    colorLink: COLORS.primary,
    colorWarning: '#FFC000',
    colorError: '#FF1F1F',
    colorBgLayout: COLORS.background,
    colorText: COLORS.text,
    colorTextBase: COLORS.text,
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
    colorTextHeading: COLORS.textPrimaryLight,
    colorTextPlaceholder: COLORS.gray400,
    colorTextLabel: COLORS.gray700,
    colorBorder: COLORS.gray300,
    borderRadius: 10,
  },

  components: {
    Layout: {
      siderBg: COLORS.white,
      headerBg: COLORS.background,
      headerPadding: 0,
      borderRadius: 12,
    },
    Menu: {
      itemBg: COLORS.white,
      popupBg: COLORS.white,
      itemColor: COLORS.text,
      // itemHoverColor: COLORS.primary,
      // itemSelectedColor: COLORS.primary,
      controlItemBgActive: COLORS.primary100,
      itemActiveBg: COLORS.primary100,
      // itemHoverBg: COLORS.primary100,
      itemSelectedBg: COLORS.primary100,
      subMenuItemBg: COLORS.white,

      controlItemBgActiveHover: COLORS.primary100,
      itemBorderRadius: 10,
      itemHeight: 45,
      fontSize: 15,
    },
    Table: {
      headerBorderRadius: 0,
      headerColor: COLORS.text,
      headerBg: COLORS.baseWhite,
      footerBg: COLORS.white,
    },
    Button: {
      borderRadius: 9999,
      fontWeight: 500,
      lineHeight: 20,
      colorTextDescription: COLORS['gray700'],
    },
    Progress: {
      colorSuccess: COLORS.success300,
      colorError: COLORS.error,
      colorInfo: COLORS.primary,
    },
    Segmented: {
      trackBg: COLORS.white,
      itemActiveBg: COLORS.primary100,
      itemSelectedBg: COLORS.primary100,
      itemSelectedColor: COLORS.title,
    },
  },
};
