import 'antd/dist/reset.css';
import 'assets/style.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider as AntdThemesProvider} from 'antd';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from 'utils/queryClientConfig';
import {RouterProvider} from 'react-router-dom';
import {THEME_ANTD} from 'themes/defaultStyles';
import {router} from 'router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AntdThemesProvider theme={THEME_ANTD}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </AntdThemesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
